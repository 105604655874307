.margin-bottom {
	margin-bottom: rem($base-line-height);
}

.highlight {
	color: $secondary;
}

.btn {
	@extend %animated-transform;
	position: relative;
	appearance: none;
	// background: $green;
	background: #4c4b4b;
	border: none;
	color: $light;
	cursor: pointer;
	display: inline-block;
	font-size: rem($base-font-size);
	line-height: rem($base-line-height);
	padding: 20px 50px 20px 25px;
	text-align: center;
	text-decoration: none;
	font-family: $main-font;
	border-radius: 15px;
	@include breakpoint(small) {
		padding: 25px 71px 25px 37px;
	}
	&:after {
		position: absolute;
		top: 20px;
		right: 20px;
		font-family: $icon-font;
		content: map-get($icon-map, long-arrow-right);
		// background: #4c4b4b;
		@include breakpoint(small) {
			top: 26px;
			right: 38px;
		}
	}

	&:hover,
	&:active,
	&:focus {
		background: $primary;
		color: $light;
	}

	*[class^="icon"] {
		margin-left: 0.5rem;
		vertical-align: middle;
	}
}

.clear {
	clear: both;
	float: none;
}

.clearfix {
	@include pie-clearfix;
}

.hidden {
	display: none !important;
}

.hideText {
	@include hide-text();
	display: inline-block;
	overflow: hidden;
	width: 0;
}

.float-left {
	float: left;
}

img.float-left {
	margin-right: 1rem;
}

.float-right {
	float: right;
}

img.float-right {
	margin-left: 1rem;
}

.fluid {
	height: auto;
	width: 100%;
}

.non-fluid {
	width: auto !important;
	img {
		width: auto !important;
	}
}

.align-left {
	text-align: left;
}

.align-right {
	text-align: right;
}

.align-center {
	text-align: center;
}

.wide {
	width: 100%;
	display: block;
}

/* 16:9 video resolutions */
.video-container {
	&:not(.video-tag) {
		position: relative;
		height: 0;
		overflow: hidden;
		padding-bottom: 56.25%;
		position: relative;

		iframe {
			border: none;
			height: 100%;
			position: absolute;
			width: 100%;
			top: 0;
			left: 0;
		}
	}

	video {
		width: 100%;
		height: auto;
	}
}
.google-maps {
	@extend .video-container;

	@include breakpoint(medium) {
		height: rem(400px);
	}
}

body {
	&:before {
		$content: "default:" + $bpContext;
		width: str_length($content) * 6pt + $base-gap * 2;

		@each $point, $width in $gridMap {
			$content: $content + "...." + $point + ":" + nth(map-get(map-get($gridMap, $point), width), 1);

			@include breakpoint($point) {
				width: str_length($content) * 6pt + $base-gap * 2;
			}
		}

		content: "#{$content}";
		display: none !important; /* Prevent from displaying. */
	}

	&.dev-mode:before,
	&.debug:before {
		background: $alert;
		border-color: $alert;
		border-style: solid;
		border-width: 0 rem($base-gap);
		color: $light;
		display: block !important;
		font-family: Courier;
		font-size: 10pt;
		left: 0;
		line-height: 2.5;
		overflow: hidden;
		position: absolute;
		right: 0;
		text-align: center;
		top: 0;
		white-space: nowrap;
		z-index: 99999;
	}
	&:hover:before {
		opacity: 0.5;
		pointer-events: none;
	}
}

// Animations
%animated-transform {
	transition: 0.3s;
}

html.in-progress {
	pointer-events: none;
	&:before {
		content: "";
		width: 100vw;
		height: 100vh;
		background: rgba(0, 0, 0, 0.5);
		position: fixed;
		top: 0;
		left: 0;
		z-index: 99999998;
	}
	&:after {
		content: "";
		position: fixed;
		top: 0;
		height: 0;
		background: url("/images/layout/loading.svg") no-repeat center center;
		height: 100vh;
		width: 100vw;
		z-index: 99999999;
		filter: invert(100%);
	}
}

// cms-4 grid system classes
.row {
	//vertical alignment
	&.stretch {
		align-items: stretch;
	}
	&.top {
		align-items: flex-start;
	}
	&.middle {
		align-items: center;
	}
	&.bottom {
		align-items: flex-end;
	}
	//horizontal alignment
	&.start {
		justify-content: flex-start;
	}
	&.center {
		justify-content: center;
	}
	&.end {
		justify-content: flex-end;
	}
	&.around {
		justify-content: space-around;
	}
	&.between {
		justify-content: space-between;
	}
}

body:not(.javascript) [data-aos] { opacity:1 !important; transform: none !important; }


/*==================================== 
	POP-UP NEWS
	=====================================*/
	.cms-container-le-popup {
		body.cms-liveeditor-is-opened & {
			&:before {
				color: black;
				content: '⚠ Pop-UP: Nachfolgender Inhalt wird als Pop-Up angezeigt, wenn der Artikel leer ist, wird auch kein Pop-Up angezeigt ⚠';
				background: orange;
				display: block;
				padding: rem(20px);
				text-align: center;
				margin-bottom: rem(20px);
			}
		}
	}
	
	.popUpContainer {
		body:not(.cms-liveeditor-is-opened) & {
			top: 0;
			left: 0;
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			height: 100vh;
			overflow: hidden;
			transition: opacity .3s, visibility .3s;
			position: fixed;
			visibility: visible;
			opacity: 1;
			z-index: 10000000;
	 
			.popUpContent {
				width: 100%;
				background: $light;
				max-height: 90vh;
				max-width: calc(100% - #{rem(110px)}); // for the close label
				overflow: hidden;
				overflow-y: auto;
		 
				@include breakpoint(giant) {
					max-width: 80%;
				}
		 
				@include breakpoint(full) {
					max-width: 70%;
				}
	
				&.padding {
					padding: rem($base-line-height * 1.5) rem($base-gap * 1.5);
	
					@include breakpoint(small) {
						padding: rem($base-line-height * 2) rem($base-gap * 2.5);
					}
	
					@include breakpoint(large) {
						padding: rem($base-line-height * 2.5) rem($base-gap * 3.5);
					}
	
					@include breakpoint(huge) {
						padding: rem($base-line-height * 3) rem($base-gap * 4);
					}
				}
	
				p:last-child {
					margin-bottom: 0;
				}
			}
		}
	 
		label {
			display:none;
	 
			body:not(.cms-liveeditor-is-opened) & {
				background: rgba(black, 0.8);
				display: flex;
				height: 100%;
				justify-content: flex-end;
				padding: rem($base-gap);
				position: absolute;
				right: 0;
				top: 0;
				width: 100%;
				z-index: -1;
		 
				i {
					cursor: pointer;
					display: block;
					height: rem(35px);
					width: rem(35px);
					position: relative;
					transition: background .3s;
		 
					&:hover {
						background: rgba(black, 0.8);
		 
						&:before, &:after {
							background: white;
						};
					}
		 
					&:before, &:after {
						content: '';
						display: block;
						position: absolute;
						top: 50%;
						left: 50%;
						width: rem(30px);
						height: rem(2px);
						background: #ddd;
					}
		 
					&:before {
						transform: translate(-50%,-50%) rotate(45deg);
					}
		 
					&:after {
						transform: translate(-50%,-50%) rotate(-45deg);
					}
				}
			}
		}
	}
	
	input[id^="popUpToggled"] {
		display: none;
		
		body:not(.cms-liveeditor-is-opened) & {
			&:not(:checked) {
				+ {
					.popUpContainer {
						position: relative;
						opacity: 0;
						visibility: hidden;
						z-index: -1000000;
						transform:scale(0);
						pointer-events: none;
						width: 0;
						height: 0;
					}
				}
			}
		}
	}
	
	.popupAway {
		body:not(.cms-liveeditor-is-opened) & {
			display: none;
		}
	}

	body:not(.cms-liveeditor-is-opened) .non-le-hidden {
		display: none;
		}
	
	.infoCon {
		background: $medium;
	
		p {
			margin-bottom: 0;
		}
	}
	
	.ausblendeCon {
		border: 4px solid $alert;
	
		p {
			margin-bottom: 0;
		}
	}
	
	.cms-container-el.cms-container-le-popup {
		min-height: 0;
	}

.container{
	position: relative;
	display: flex;
	text-align: center;
	// &:before{
	// 	@extend .background-images;
	// 	opacity: 0.5;
	// 	background-image: url("/images/layout/chilli_popup.jpg");
	// 	width: 27%;
	// 	height: 70%;
	// 	z-index: 0 !important;
	// 	bottom: 0;
	// 	left: 10px!important;
	// 	top: unset!important;
	// }
	&.padding {
		padding: rem($base-line-height * 1) rem($base-gap * .5);

		@include breakpoint(small) {
			padding: rem($base-line-height * 2.5) rem($base-gap * 2.5);
		}

		@include breakpoint(large) {
			padding: rem($base-line-height * 3) rem($base-gap * 3.5);
		}

		@include breakpoint(huge) {
			padding: rem($base-line-height * 3.5) rem($base-gap * 4);
		}
	}
}