// * Converts a pixel value to em, relative to the body font size (or any other value).
// *
// * Usage:
// * font-size:em(12px) (if based on body font size)
// * font-size:em(12px, 18px) (if based on a parent with a relativ font size of 18px)
// *
// * @param	{string} $to-size	 Target pixel value
// * @param	{string} $from-size: $base-font-size (optional) Font size of the parent
// * @return {string}			 Calculated em value
@function em($to-size, $from-size: $base-font-size) {
	@if $to-size == 0px {
		@return 0;
	}
	@return $to-size / $from-size * 1em;
}

// * Converts a pixel value to rem, relative to the body font size (or any other value).
// *
// * Usage:
// * font-size:rem(12px) (if based on body font size)
// * font-size:rem(12px, 18px) (if based on a parent with a relativ font size of 18px)
// *
// * @param	{string} $to-size	 Target pixel value
// * @param	{string} $from-size: $base-font-size (optional) Font size of the parent
// * @return {string}			 Calculated rem value
@function rem($to-size, $from-size: $base-font-size) {
	@if $to-size == 0px {
		@return 0;
	}
	@return $to-size / $from-size * 1rem;
}

// * Calculates the percent-based body font size based on a pixel value.
// *
// * Usage:
// *
// * body {
// *		 font-size:body-font-size();
// * }
// *
// * @type {string} $pixel-value The layout’s base font size
//
@function body-font-size($pixel-value: $base-font-size) {
	@return ($base-font-size / 1px) * 100 / 16#{"%"};
}

/// String Replace Function
/// @group functions
/// @param $string
/// @param $search
/// @param $replace [""]
@function str-replace($string, $search, $replace: "") {
	$index: str-index($string, $search);
	@if $index {
		@return str-slice($string, 1, $index - 1) + $replace +
			str-replace(
				str-slice($string, $index + str-length($search)),
				$search,
				$replace
			);
	}
	@return $string;
}

/// SCSS @font-face mixin
/// @author [Malte Zoudlik](https://github.com/ma-zou
/// @param $font-name - font-family
/// @param $file-name - URL to fonts
/// @param $weights [null] - font-weight
/// @param $types (eot woff2 woff ttf svg) - font extensions to add to src
/// @param $style [null] - font-style



@mixin font-face-V2($font-name, $file-name, $weights: null, $types: woff, $style: null) {
	@if $types == all {
		$types: (woff2, woff, ttf, svg, eot);
	}
	$extmods: (
		svg: "#" + str-replace($font-name, " ", ""),
		eot: "?#iefix"
	);
	$formats: (
		ttf: "truetype",
		eot: "embedded-opentype"
	);

	@each $weight in $weights {
		$src: null;
		$srcWeight: "";
		@if($weight != null) {
			$weight: to-string($weight);
			@if (str-index($weight, "400")) {
				$srcWeight: if(str-index($weight, "italic"), "italic", "regular");
			} @else {
				$srcWeight: str-replace($weight, "|", "");
			}

			$srcWeight: "-" + $srcWeight;
		}
		@each $type in $types {
			$extmod: if(
				map-has-key($extmods, $type),
				$type + map-get($extmods, $type),
				$type
			);
			$format: if(map-has-key($formats, $type), map-get($formats, $type), $type);
			$src: append(
				$src,
				url(quote("/fonts/" + $file-name + $srcWeight + "." + $extmod)) format(quote($format)),
				comma
			);
		}
		@font-face {
			font-family: quote($font-name);
			$weight: to-string($weight);
			@if(str-index($weight, '|italic')) {
				font-weight: unquote(str-slice($weight, 1,	str-index($weight, '|italic') - 1));
				font-style: italic;
			} @else if ($weight != null) { 
				font-weight: unquote($weight);
			}
			@if($style != null) {
				font-style: $style;
			} @else if not (str-index($weight, '|italic'))	{
				font-style: normal;
			}
			font-display: swap;
			src: $src;
			
		}
	}
}
 
