* {
	margin: 0;
	padding: 0;

	&,
	&:before,
	&:after {
		box-sizing: inherit;
	}
}

::selection {
	background-color: rgba($green, 0.3);
}
::-moz-selection {
	background-color: rgba($green, 0.3);
}

// DO NOT set font-size and line-height here!
// Adjust $base-font-size and $base-line-height in _config.scss
html {
	background: $light;
	box-sizing: border-box;
	color: $dark;
	font-family: $main-font;
	font-size: 100% * ($base-font-size / 16px);
	hyphens: manual;
	line-height: rem($base-line-height);
	scroll-behavior: smooth;

	/* disable text resize in landscape. e.g. on iphone */
	text-size-adjust: none;
	overflow-x: hidden;
}

body {
	line-height: rem($base-line-height);
	opacity: 1 !important;
	overflow-x: hidden;
}

iframe,
[data-iframe] {
	border: none;
	width: 100%;
}

address {
	font-style: normal;
}

/**
 * Headlines
 */

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	display: block;
	text-rendering: optimizeLegibility;
	hyphens: auto;
	font-weight: 700;
	margin-bottom: rem($base-line-height);
	color: $primary;
	span {
		display: block;
		font-weight: 300;
		color: $dark;
	}

	/* Use this to let headlines break in Chrome Desktop
	body.platformWindows.chrome &, body.platformLinux.chrome & {
		word-break: break-all;
	}
	*/
}

h1,
.h1 {
	position: relative;
	font-family: $display-font;
	font-size: rem(25px);
	line-height: rem(35px);
	text-align: center;
	padding-bottom: rem(55px);
	margin-bottom: rem(32px);
	@include breakpoint(medium) {
		font-size: rem(30px);
		line-height: rem(40px);
	}
	@include breakpoint(large) {
		font-size: rem($h1-size);
		line-height: rem(43px);
	}
	span {
		font-size: rem(20px);
		line-height: rem(30px);
		@include breakpoint(medium) {
			font-size: rem(25px);
			line-height: rem(35px);
		}
		@include breakpoint(large) {
			font-size: rem(28px);
			line-height: rem(38px);
		}
	}
	&:before,
	&:after {
		position: absolute;
		content: "";
		left: 50%;
		transform: translateX(-50%);
		bottom: 0;
	}
	&:after {
		width: rem(52px);
		height: rem(35px);
		background: image-url("layout/title-flower.jpg") no-repeat center center/contain;
	}
	&:before {
		width: rem(211px);
		border-bottom: 2px dotted $medium;
		bottom: rem(15px);
	}
}

h2,
.h2 {
	font-size: rem(22px);
	line-height: rem(32px);
	margin-bottom: rem(11px);
	@include breakpoint(medium) {
		font-size: rem(23px);
		line-height: rem(33px);
	}
	@include breakpoint(large) {
		font-size: rem($h2-size);
		line-height: rem(34px);
	}
}

h3,
.h3 {
	font-size: rem(22px);
	line-height: rem(32px);
	margin-bottom: rem(16px);
	@include breakpoint(medium) {
		font-size: rem(23px);
		line-height: rem(33px);
	}
	@include breakpoint(large) {
		font-size: rem($h3-size);
		line-height: rem(34px);
	}
	body.main-template & {
		margin-top: 45px;
	}
}

h4,
.h4 {
	font-size: rem($h4-size);
}

h5,
.h5 {
	font-size: rem($h5-size);
}

h6,
.h6 {
	font-size: rem($h6-size);
}

/**
 * Links
 */
a {
	@extend %animated-transform;
	color: $green;
	word-wrap: break-word;
	text-decoration: none;

	&:hover,
	&:focus,
	&:active {
		color: $dark;
		text-decoration: none;
	}

	img {
		border: none;
	}

	&[href^="tel"] {
		color: inherit;
		text-decoration: none;
	}
}

a:not(.btn):focus,
[tabindex]:focus {
	outline: $outline;
	outline-offset: rem(5px);
}

hr,
.hr {
	display: flex;
	background: $medium;
	border: none;
	clear: both;
	height: rem(1px);
	margin: 1rem 0;
	flex-basis: 100%;
}

ul,
ol {
	list-style: none;

	&.styled-list {
		li {
			position: relative;
			padding-left: rem(23px);
			margin-bottom: rem(17px);

			&:before {
				position: absolute;
				@extend .icon;
				@extend .icon-arrow-right;
				top: rem(6px);
				left: 0;
				color: $primary;
				font-weight: 700;
			}
		}

		ul {
			margin-left: 1rem;
			margin-bottom: 0;
		}
	}
}

dl {
	&.styled-list {
		@include pie-clearfix;

		dt,
		dd {
			display: block;
			background: rgba($green, 0.1);
			padding: rem(15px);
		}

		dt {
			padding-bottom: 0;
			font-weight: bold;
		}

		dd {
			padding-top: 0;

			+ dt {
				margin-top: rem(10px);
			}

			+ dd {
				margin-top: rem(-15px);
			}
		}
	}

	&.float-list {
		@include pie-clearfix;

		dt,
		dd {
			display: block;
			vertical-align: top;
			float: left;
		}

		dt {
			padding-right: 0.5rem;
			width: 40%;
			clear: left;
		}

		dd {
			width: 60%;
		}
	}
	&.footer-styled-list {
		@include pie-clearfix;
		text-align: center;
		@include breakpoint(medium) {
			text-align: left;
		}
		dt,
		dd {
			vertical-align: top;
			text-align: center;
			white-space:nowrap;
			@include breakpoint(medium) {
				float: left;
				text-align: left;
			}
			@include breakpoint(giant){
				white-space: normal;
			}
		}

		dt {
			font-weight: 700;
			padding-right: 0;
			display: inline;
			@include breakpoint(medium) {
				clear: left;
				width: 73px;
			}
		}

		dd {
			display: inline;
			&:after {
				content: "";
				display: block;
			}
			@include breakpoint(medium) {
				width: calc(100% - 73px);
			}
		}
	}
	&.fsl1 {
		margin-top: rem(28px);
	}
	&.fsl2 {
		dt {
			@include breakpoint(medium) {
				width: 160px;
			}
		}

		dd {
			@include breakpoint(medium) {
				width: calc(100% - 160px);
			}
		}
	}
}

ol {
	&.styled-list {
		counter-reset: item;

		li {
			counter-increment: item;

			&:before {
				content: counters(item, ".") ". ";
			}
		}

		ol {
			margin-bottom: 0;

			li {
				&:before {
					content: counters(item, ".") " ";
					margin-right: rem(5px);
				}
			}
		}
	}
}

/*
 * responsive images
 */

img {
	vertical-align: bottom;
	float: none;
	height: auto;
	max-width: 100%;
	width: 100%;

	[data-rel] &,
	.lightbox-image & {
		margin-bottom: 0;
	}

	&[data-src] {
		@extend %lazy-loading;
	}
}

/**
 * Tables
 */
main {
	table {
		display: table;
		border-collapse: collapse;
		width: 100%;
		max-width: 100%;
		text-align: left;
		font-size: rem($base-font-size);

		@include breakpoint(small, max) {
			overflow-x: auto;
			display: block;
		}

		caption {
			text-align: left;
			padding: rem($base-gap) rem($base-gap) rem(25px);
			display: table-caption;
			font-weight: bold;
			font-size: 1.2rem;
		}

		thead {
			border-collapse: collapse;
			display: table-header-group;
		}

		tbody {
			width: 100%;
			overflow-x: scroll;
			display: table-row-group;

			tr {
				padding: rem($base-gap) 0;

				&:not(:last-child) {
					border-bottom: rem(1px) solid $medium;
				}
			}
		}

		tr {
			display: table-row;
		}

		th,
		td {
			padding: rem($base-gap);
			display: table-cell;
			vertical-align: top;
		}

		@include breakpoint(small, max) {
			td {
				min-width: 50vw;
			}
		}
	}
}

[data-aos] {
	@include breakpoint(large, max) {
		opacity: 1 !important;
		transform: none !important;
	}
}
