// Use this for default Icomoon

@include font-face-V2(
 $font-name: "icomoon",
 $file-name: "icomoon",
 $types: (woff, ttf, svg, eot),
 $style: normal,
);

@include font-face-V2(
    $font-name: "Lato", 
    $file-name: "lato", 
    $weights: ("300", "300|italic", "400", "400|italic", "500", "500|italic", "700", "700|italic", "900", "900|italic"), 
    $types: (woff, woff2)
);