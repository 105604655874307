/* This file was automaticaly generated by the DFS worker. Changes here will be overwritten. 
 To do change edit the config.json file! */ 

$image-path: "../images/" !default;

$base-font-size: 18px;

$base-line-height: 28px;

$h1-size: 33px;

$h2-size: 24px;

$h3-size: 24px;

$h4-size: 22px;

$h5-size: 20px;

$h6-size: 20px;

$main-font: Lato, sans-serif;

$display-font: Lato, sans-serif;

$icon-font: icomoon, sans-serif;

// Project colors

$primary: #01642d;
$secondary: #3b9941;
$light: #fff;
$medium: #6a6a6a;
$dark: #4c4b4b;
$border: #bbb;
$alert: #D85D00;
$dark-grey: #2c2c2c;
$grey: #696969;
$green: #3b9941;
$dark-green: #00632b;
$light-green: #d8f1e3;

$base-gap: 18px;

$rowMaxWidth: 1620px;

$columnCount: 12;

$column-count: $columnCount;

$base-border: 1px solid $border;

$base-box-shadow: 0px 5px 10px 0px $medium;

$outline: 1px dotted $alert;

$bpContext: 16px;

$hide-text-direction: right;

$icon-map: (
	times: "\e909", 
	minus: "\e90c", 
	exclamation: "\e90d", 
	plus: "\e90a", 
	angle-right: "\ea1f", 
	angle-up: "\ea21", 
	file-pdf: "\eadf", 
	clock2: "\e94e", 
	phone: "\e906", 
	pencil: "\e907", 
	long-arrow-right: "\e900", 
	long-arrow-left: "\e901", 
	long-arrow-up: "\e904", 
	long-arrow-down: "\e905", 
	map-marker: "\ea3d", 
	arrow-right: "\ea2a", 
);

// Project gridMap

$gridMap: (
	default: (
		width: em(0px, $bpContext), 
		cols: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, auto),
		prefix: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		suffix: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		start: (justify-content:flex-start),
		center: (justify-content:center),
		end: (justify-content:flex-end),
		between: (justify-content:space-between),
		around: (justify-content:space-around),
		top: (align-items:flex-start),
		middle: (align-items:center),
		baseline: (align-items:flex-baseline),
		bottom: (align-items:flex-end),
		stretch: (align-items:stretch),
		reverse: (flex-direction:row-reverse),
		forward: (flex-direction:row),
		first: (order:-1),
		last: (order:1),
		unset: (order:0),
	),
	tiny: (
		width: em(480px, $bpContext), 
		cols: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, auto),
		prefix: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		suffix: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		start: (justify-content:flex-start),
		center: (justify-content:center),
		end: (justify-content:flex-end),
		between: (justify-content:space-between),
		around: (justify-content:space-around),
		top: (align-items:flex-start),
		middle: (align-items:center),
		baseline: (align-items:flex-baseline),
		bottom: (align-items:flex-end),
		stretch: (align-items:stretch),
		reverse: (flex-direction:row-reverse),
		forward: (flex-direction:row),
		first: (order:-1),
		last: (order:1),
		unset: (order:0),
	),
	small: (
		width: em(640px, $bpContext), 
		cols: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, auto),
		prefix: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		suffix: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		start: (justify-content:flex-start),
		center: (justify-content:center),
		end: (justify-content:flex-end),
		between: (justify-content:space-between),
		around: (justify-content:space-around),
		top: (align-items:flex-start),
		middle: (align-items:center),
		baseline: (align-items:flex-baseline),
		bottom: (align-items:flex-end),
		stretch: (align-items:stretch),
		reverse: (flex-direction:row-reverse),
		forward: (flex-direction:row),
		first: (order:-1),
		last: (order:1),
		unset: (order:0),
	),
	medium: (
		width: em(760px, $bpContext), 
		cols: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, auto),
		prefix: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		suffix: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		start: (justify-content:flex-start),
		center: (justify-content:center),
		end: (justify-content:flex-end),
		between: (justify-content:space-between),
		around: (justify-content:space-around),
		top: (align-items:flex-start),
		middle: (align-items:center),
		baseline: (align-items:flex-baseline),
		bottom: (align-items:flex-end),
		stretch: (align-items:stretch),
		reverse: (flex-direction:row-reverse),
		forward: (flex-direction:row),
		first: (order:-1),
		last: (order:1),
		unset: (order:0),
	),
	large: (
		width: em(992px, $bpContext), 
		cols: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, auto),
		prefix: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		suffix: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		start: (justify-content:flex-start),
		center: (justify-content:center),
		end: (justify-content:flex-end),
		between: (justify-content:space-between),
		around: (justify-content:space-around),
		top: (align-items:flex-start),
		middle: (align-items:center),
		baseline: (align-items:flex-baseline),
		bottom: (align-items:flex-end),
		stretch: (align-items:stretch),
		reverse: (flex-direction:row-reverse),
		forward: (flex-direction:row),
		first: (order:-1),
		last: (order:1),
		unset: (order:0),
	),
	giant: (
		width: em(1200px, $bpContext), 
		cols: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, auto),
		prefix: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		suffix: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		start: (justify-content:flex-start),
		center: (justify-content:center),
		end: (justify-content:flex-end),
		between: (justify-content:space-between),
		around: (justify-content:space-around),
		top: (align-items:flex-start),
		middle: (align-items:center),
		baseline: (align-items:flex-baseline),
		bottom: (align-items:flex-end),
		stretch: (align-items:stretch),
		reverse: (flex-direction:row-reverse),
		forward: (flex-direction:row),
		first: (order:-1),
		last: (order:1),
		unset: (order:0),
	),
	huge: (
		width: em(1364px, $bpContext), 
		cols: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, auto),
		prefix: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		suffix: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		start: (justify-content:flex-start),
		center: (justify-content:center),
		end: (justify-content:flex-end),
		between: (justify-content:space-between),
		around: (justify-content:space-around),
		top: (align-items:flex-start),
		middle: (align-items:center),
		baseline: (align-items:flex-baseline),
		bottom: (align-items:flex-end),
		stretch: (align-items:stretch),
		reverse: (flex-direction:row-reverse),
		forward: (flex-direction:row),
		first: (order:-1),
		last: (order:1),
		unset: (order:0),
	),
	full: (
		width: em(1520px, $bpContext), 
		cols: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, auto),
		prefix: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		suffix: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		start: (justify-content:flex-start),
		center: (justify-content:center),
		end: (justify-content:flex-end),
		between: (justify-content:space-between),
		around: (justify-content:space-around),
		top: (align-items:flex-start),
		middle: (align-items:center),
		baseline: (align-items:flex-baseline),
		bottom: (align-items:flex-end),
		stretch: (align-items:stretch),
		reverse: (flex-direction:row-reverse),
		forward: (flex-direction:row),
		first: (order:-1),
		last: (order:1),
		unset: (order:0),
	),
	cut: (
		width: em($rowMaxWidth, $bpContext), 
		cols: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, auto),
		prefix: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		suffix: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		start: (justify-content:flex-start),
		center: (justify-content:center),
		end: (justify-content:flex-end),
		between: (justify-content:space-between),
		around: (justify-content:space-around),
		top: (align-items:flex-start),
		middle: (align-items:center),
		baseline: (align-items:flex-baseline),
		bottom: (align-items:flex-end),
		stretch: (align-items:stretch),
		reverse: (flex-direction:row-reverse),
		forward: (flex-direction:row),
		first: (order:-1),
		last: (order:1),
		unset: (order:0),
	),
	cut2: (
		width: [em(1720px, $bpContext)], 
		cols: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, auto),
		prefix: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		suffix: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12),
		start: (justify-content:flex-start),
		center: (justify-content:center),
		end: (justify-content:flex-end),
		between: (justify-content:space-between),
		around: (justify-content:space-around),
		top: (align-items:flex-start),
		middle: (align-items:center),
		baseline: (align-items:flex-baseline),
		bottom: (align-items:flex-end),
		stretch: (align-items:stretch),
		reverse: (flex-direction:row-reverse),
		forward: (flex-direction:row),
		first: (order:-1),
		last: (order:1),
		unset: (order:0),
	),
);

