$nav-height: calc(100vh - #{rem($bar-height)});

@mixin page-navi($class: "#navigation") {
	#{$class} {
		position: fixed;
		max-width: 100%;
		right: 0;
		@content;
	}
}
@mixin ul {
	ul {
		width: 100%;
		@content;
	}
}
@mixin nav-buttons {
	a,
	&:not(.nav-back) > span,
	&.nav-back,
	&.has-sub > span {
		@extend %animated-transform;
		display: block;
		cursor: pointer;
		@content;
	}
	a,
	&.has-sub > span {
		&.active {
			@extend %button-active;
		}
	}
	&:hover {
		&.nav-back,
		> a,
		&:not(.nav-back) > span {
			@extend %button-hover;
		}
	}
}
@mixin has-sub($class: ".has-sub") {
	&#{$class} {
		> span,
		> a {
			display: flex;
			justify-content: space-between;
			align-items: center;
			@content;

			&:after {
				@extend %next-icon;
				margin-left: 0.5em;
				display: inline-block;
				font-size: rem(14px);
				font-weight: 700;
			}
		}
		&.active {
			> a,
			> span {
				@extend %button-active;
			}
		}
		@content;
	}
}
@mixin sub($class: ".sub") {
	#{$class} {
		// @extend %animated-transform;
		position: absolute;
		left: 0;
		top: 0;
		@content;

		@include breakpoint($break, max) {
			transform: translateX(100%);
		}
		@include breakpoint($break) {
			opacity: 0;
			visibility: hidden;
		}
		&.current {
			@include breakpoint($break, max) {
				transform: translateX(0%);
			}
			@include breakpoint($break) {
				opacity: 1;
				visibility: visible;
			}
		}
	}
}
@mixin nav-back {
	&.nav-back {
		// @extend %animated-transform;
		position: relative;
		@content;

		&:before {
			@extend %prev-icon;
			margin-right: 1em;
			display: inline-block;
		}
	}
}

@include breakpoint($break, max) {
	#navigation {
		min-width: 75%;
		overflow: hidden;

		@if $bar-pos == "top" {
			top: rem($bar-height);
			bottom: 0;
		} @else if $bar-pos == "bottom" {
			top: 0;
			bottom: rem($bar-height);
		}
		&.first-level {
			nav {
				height: $nav-height;
				overflow: hidden;
				overflow-y: auto;
			}
		}
		nav {

			ul {
				
				&.navi {
					li {
						&.has-sub {
							.sub {
								height: $nav-height;
								
								&.open {
									-webkit-overflow-scrolling: touch;
									overflow: hidden;
									overflow-y: auto;
								}
								&:before {
									content: attr(data-parent-name);
									display:block;
								}
							}
						}
					}
				}
			}
		}
	}
	html.hidden-scroll {
		overflow: hidden;
	}
}

@mixin desktop-version-functionality {
	#navigation {
		top: 0;
		width: 100%;

		nav {
			ul {
				&.navi {
					display: flex;
					justify-content: space-between;

					li {
						a,
						&:not(.nav-back) > span,
						.sub:before,
						.nav-back {
							white-space: nowrap;
						}
						&.has-sub {
							position: relative;
							body.cms-backend & {
								&:hover {
									> .sub {
										opacity:1;
										visibility: visible;
									}
								}
							}
							.sub {
								top: 100%;
								width: auto;

								.sub {
									left: 100%;
									top: 0;
								}
							}
						}
						&.nav-back {

							&:before {
								content: $close-icon;
								text-align: center;
								width: 100%;
							}
							span {
								display: none;
							}
						}
					}
				}
			}
		}

		&.hover-navigation nav ul.navi li.nav-back {
			display:none;
		}
	}
}

#quickbar {
	position: fixed;
	left: 0;
	width: 100%;

	@include breakpoint($break, max) {
		height: rem($bar-height);
	}

	@if $bar-pos == "top" {
		top: 0;

		@if $bar-hide {
			&.hide {
				transform: translateY(-100%);
			}
		}
	} @else if $bar-pos == "bottom" {
		bottom: 0;

		@if $bar-hide {
			&.hide {
				transform: translateY(100%);
			}
		}
	}

	label[for="navi-toggled"] {
		display: block;
		cursor: pointer;
		width: rem($bar-height * 0.4);
		height: rem($bar-height * 0.4);
		position: relative;
		overflow: hidden;

		&:after {
			// @extend %animated-transform;
			content: "";
			position: fixed;
			left: 0;
			width: 200vw;
			height: 200vh;
			display: block;
			background-color: $dark;
			opacity: 0.9;
			z-index: -1;

			@if $bar-pos == "top" {
				top: rem($bar-height);
			} @else if $bar-pos == "bottom" {
				bottom: rem($bar-height);
			}
		}

		span {
			transition: 0.4s linear;
			text-indent: -9999px;
			width: 0;
			top: 50%;
			transform: translate(-50%, -50%);

			&,
			&:before,
			&:after {
				content: "";
				position: absolute;
				display: block;
				background: $bar-color;
				height: 2px;
				left: 50%;
				transform-origin: center;
			}
			&:before,
			&:after {
				top: calc(50% + 0rem);
				width: rem($bar-height * 0.4);
				transition: top 0.3s ease-in-out, transform 0.3s 0.2s linear;
			}
			&:before {
				transform: translate(-50%, -50%) rotate(45deg);
			}
			&:after {
				transform: translate(-50%, -50%) rotate(-45deg);
			}
		}
	}
}

#navi-toggled {
	display: none;

	&:not(:checked) {
		~ * #navigation,
		~ #navigation {
			@include breakpoint($break, max) {
				transform: translateX(100%);
			}
		}
		~ #quickbar {
			label[for="navi-toggled"] {
				&:after {
					opacity: 0;
					visibility: hidden;
				}
				span {
					width: rem($bar-height * 0.4);

					&:before,
					&:after {
						transform: translate(-50%, -50%);
						transition: top 0.3s 0.2s ease-in-out, transform 0.3s linear;
					}
					&:before {
						top: calc(50% + -#{rem(($bar-height * 0.4) * 0.25)});
					}
					&:after {
						top: calc(50% + #{rem(($bar-height * 0.4) * 0.25)});
					}
				}
			}
		}
	}
}


//allow transition of navigation element
body.javascript {
	#navigation, .sub {
		transition: transform .3s ease, opacity .3s ease;
	}
}
