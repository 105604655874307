// HEADER
//////////////////////////////

#header {
	position: relative;
	width: 100%;
	padding-top: rem(50px);
	@include breakpoint(large) {
		padding-top: rem(122px);
	}
	.branding {
		display: block;
		max-width: rem(159px);
		margin: rem(30px) auto;
		@include breakpoint(large) {
			position: fixed;
			// max-width: rem(138px);
			max-width: rem(158px);

			top: rem(22px);
			margin: 0;
			z-index: 1001;
		}
		@include breakpoint(giant) {
			// max-width: rem(140px);
			max-width: rem(165px);

			top: rem(22px);
		}
		@include breakpoint(huge) {
			top: rem(22px);
			max-width: rem(177px);
		}
		body.cms-template-edit &,
		body.cms-page-edit & {
			display: none;
		}
	}
	.header-content {
		@include responsive-image("layout/header-bg.jpg", (tiny, small, medium, large), false);
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
		padding: rem(45px) 0;
		@include breakpoint(large) {
			padding: rem(58px) rem(18px);
		}
		@include breakpoint(giant) {
			body:not(.cms-template-edit) & {
				background-attachment: fixed;
			}
		}
		body.sub-template & {
			padding: rem(60px) 0 rem(50px);
			@include breakpoint(large) {
				padding: rem(94px) 0 rem(76px);
			}
		}
		.flower-col {
			@include breakpoint(large) {
				// padding-left: 0;
			}
		}
		// .flower-bg {
		// 	@include responsive-image("layout/flower-bg.jpg", (tiny, small, medium, large), false);
		// 	position: relative;
		// 	width: 100%;
		// 	height: 100%;
		// 	min-height: rem(200px);
		// 	background-size: cover;
		// 	background-position: center center;
		// 	background-repeat: no-repeat;
		// 	@include breakpoint(tiny) {
		// 		min-height: rem(200px);
		// 	}
		// 	.pop-container {
		// 		display: flex;
		// 		align-items: center;
		// 		justify-content: center;
		// 		position: absolute;
		// 		max-width: rem(392px);
		// 		right: 50%;
		// 		bottom: 50%;
		// 		transform: translate(50%, 50%);
		// 		padding: rem(7px) rem(30px) rem(7px) rem(7px);
		// 		background: #fcf6fc;
		// 		@include breakpoint(large) {
		// 			right: rem(20px);
		// 			bottom: rem(37px);
		// 			transform: none;
		// 		}
		// 		@include breakpoint(giant) {
		// 			right: rem(46px);
		// 			bottom: rem(37px);
		// 		}
		// 		body:not(.cms-template-edit) & {
		// 			opacity: 0;
		// 			animation: appear2 1s 3s forwards;
		// 			@include breakpoint(large) {
		// 				animation: appear 1s 3s forwards;
		// 			}
		// 		}
		// 		figure {
		// 			width: 100%;
		// 			min-width: rem(85px);
		// 			max-width: rem(85px);
		// 			height: 100%;
		// 			margin: 0 rem(24px) 0 0;
		// 		}
		// 		.cms-article {
		// 			font-size: rem(16px);
		// 			line-height: rem(22px);
		// 			strong {
		// 				display: block;
		// 				font-size: rem(17px);
		// 				font-weight: 900;
		// 				margin-bottom: rem(7px);
		// 				color: #343434;
		// 			}
		// 		}
		// 	}
		// }
		.claim-col {
			@include breakpoint(large) {
				// padding-right: 0;
			}
		}
		.claim-holder {
			padding: rem(57px) rem(18px);
			background-color: #fcf6fc;
			@include breakpoint(tiny) {
				padding: rem(57px) rem(38px);
			}
			@include breakpoint(small) {
				padding: rem(57px) rem(49px);
			}
			@include breakpoint(large) {
				padding: rem(57px) rem(18px) rem(84px);
			}
			@include breakpoint(giant) {
				padding: rem(57px) rem(49px) rem(84px);
			}
			figure {
				text-align: center;
				margin-bottom: rem(28px);
			}
			body.sub-template & {
				background: none;
				padding: 0;
			}
			.claim {
				max-width: rem(425px);
				margin: 0 auto;
				// li {
				// 	&.h1 {
				// 		margin-bottom: rem(33px);
				// 		padding-bottom: 0;
				// 		@include breakpoint(tiny) {
				// 			text-align: left;
				// 		}
				// 		&:before,
				// 		&:after {
				// 			display: none;
				// 		}
				// 	}
				// 	&:nth-child(2),
				// 	&:nth-child(3),
				// 	&:nth-child(4) {
				// 		position: relative;
				// 		font-size: rem(18px);
				// 		border-bottom: 1px dotted $medium;
				// 		padding: 0 0 rem(18px) rem(63px);
				// 		margin-bottom: rem(18px);
				// 		@include breakpoint(tiny) {
				// 			font-size: rem(20px);
				// 		}
				// 		&::before {
				// 			position: absolute;
				// 			content: "";
				// 			width: rem(40px);
				// 			height: rem(40px);
				// 			top: 50%;
				// 			left: 0;
				// 			transform: translateY(-76%);
				// 			@include breakpoint(tiny) {
				// 				top: rem(-9px);
				// 				transform: none;
				// 			}
				// 		}
				// 	}
				// 	&:nth-child(2) {
				// 		&::before {
				// 			background: image-url("layout/flower-girl.svg") no-repeat center center/contain;
				// 		}
				// 	}
				// 	&:nth-child(3) {
				// 		&::before {
				// 			background: image-url("layout/flowers.svg") no-repeat center center/contain;
				// 		}
				// 	}
				// 	&:nth-child(4) {
				// 		margin-bottom: rem(28px);
				// 		border-bottom: none;
				// 		&::before {
				// 			background: image-url("layout/flower-guy.svg") no-repeat center center/contain;
				// 			@include breakpoint(tiny) {
				// 				top: rem(-3px);
				// 			}
				// 		}
				// 	}
				// }
				.btn {
					width: auto;
					padding: rem(20px) rem(40px) rem(20px) rem(40px);
					@include breakpoint(tiny) {
						padding: rem(20px) rem(20px) rem(20px) rem(40px);
					}
					@include breakpoint(small) {
						padding: rem(24px) rem(38px) rem(25px) rem(68px);
					}
					@include breakpoint(large) {
						width: 100%;
					}
					&:before {
						position: absolute;
						content: map-get($icon-map, pencil);
						font-family: icomoon;
						font-size: rem(18px);
						font-weight: 400;
						top: rem(21px);
						left: rem(17px);
						@include breakpoint(small) {
							font-size: rem(21px);
							top: rem(25px);
							left: rem(44px);
						}
					}
					&:after {
						display: none;
					}
					strong {
						display: block;
						@include breakpoint(tiny) {
							display: inline-block;
						}
					}
				}
				.btn-li {
					position: relative;
					z-index: 1;
					max-width: fit-content;
					margin: 0 auto;
					&::before {
						position: absolute;
						content: "";
						width: rem(38px);
						height: rem(38px);
						top: rem(-19px);
						left: 50%;
						transform: translateX(-50%);
						background: image-url("layout/white-flower.png") no-repeat center center/contain;
						z-index: 1;
					}
					.btn {
						position: relative;
						width: 100%;
						&:after {
							display: block;
							@extend %animated-transform;
							position: absolute;
							content: "";
							background: $green;
							border-radius: 50%;
							width: rem(76px);
							height: rem(76px);
							top: rem(-31px);
							left: 50%;
							transform: translateX(-50%);
							z-index: -1;
							background:#4c4b4b;
						}
						&:hover,
						&:focus,
						&:active {
							&::after {
								background: $primary;
							}
						}
					}
				}
			}
		}
	}
}


// new header

.header-content{
	.imageHolder{
		position: relative;
		min-height: rem(230px);
		display: flex;
		justify-content: center;
		align-items: flex-end;

		@include breakpoint(medium){
			min-height: rem(250px);
		}

		@include breakpoint(large){
			min-height: rem(270px);
		}

		@include breakpoint(giant){
			min-height: rem(323px);
		}

		@include breakpoint(huge){
			min-height: rem(393px);
		}

		@include breakpoint(full){
			min-height: rem(493px);
		}


		&.image01{
			background: image-url("layout/header/headerImg01-small.jpg") no-repeat center center/cover;
			background-position: bottom;
			margin-bottom: rem($base-gap * 2);
			@include breakpoint(medium){
				margin-bottom: 0;
				background: image-url("layout/header/headerImg01.jpg") no-repeat center center/cover;
			}
			@include breakpoint(huge){
				background-position: center;
			}
		}

		&.image02{
			background: image-url("layout/header/headerImg02-small.jpg") no-repeat center center/cover;
			margin-bottom: rem($base-gap * 2);
			@include breakpoint(medium){
				margin-bottom: 0;
				background: image-url("layout/header/headerImg02.jpg") no-repeat center center/cover;
			}
		}

		&.image03{
			background: image-url("layout/header/headerImg03-small.jpg") no-repeat center center/cover;
			@include breakpoint(medium){
				background: image-url("layout/header/headerImg03.jpg") no-repeat center center/cover;
			}
		}

		&:before{
			content: '';
			position: absolute;
			// background: rgba(#000, 0.2);
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			@extend %animated-transform;
			z-index: 0;
		}

		&:after{
			content: '';
			position: absolute;
background: rgb(0,0,0);
background: linear-gradient(360deg, rgba(0,0,0,0.3981967787114846) 0%, rgba(255,255,255,0) 52%);
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			@extend %animated-transform;
			z-index: 0;
		}


		&:hover,
		&:focus,
		&.active{
			&:before{
				background: rgba($primary, 0.5);
			}
			.textLink{
				padding-bottom: rem(50px);
				@include breakpoint(giant){
					padding-bottom: rem(70px);
				}
			}
		}

		.textLink{
			font-size: rem(19px);
			font-weight: 700;
			color: $light;
			padding-bottom: rem(25px);
			@extend %animated-transform;
			z-index: 0;



			@include breakpoint(medium){
				font-size: rem(21px);
			}

			@include breakpoint(giant){
				font-size: rem(24px);
				padding-bottom: rem(35px);
			}
		}
	}	
}

// MAIN CONTENT
//////////////////////////////

main {
	display: block;
	.sub-template & {
		padding: rem(60px) 0 rem(100px);
		@include breakpoint(large) {
			padding: rem(100px) 0 rem(130px);
		}
		@include breakpoint(huge) {
			padding: rem(100px) 0 rem(160px);
		}
	}

	p,
	ol,
	ul,
	dl,
	table {
		@extend .margin-bottom;
	}

	ul:not(.unstyled-list),
	dl:not(.unstyled-list),
	ol:not(.unstyled-list) {
		@extend .styled-list;
	}

	img {
		margin-bottom: rem($base-gap * 2);
	}

	.section {
		padding: rem(60px) 0;
		@include breakpoint(large) {
			padding: rem(100px) 0;
		}
	}
	.sub-section {
		+ .sub-section {
			.border-col {
				position: relative;
				padding-top: rem(59px);
				margin-top: rem(59px);
				&::before,
				&::after {
					position: absolute;
					content: "";
					left: 50%;
					transform: translateX(-50%);
					z-index: -1;
				}
				&:before {
					width: 100%;
					max-width: calc(1620px - 36px);
					top: 0;
					border-top: 2px dotted $medium;
				}
				&::after {
					top: rem(-17px);
					width: rem(70px);
					height: rem(33px);
					background: image-url("layout/sec-sep-flower.jpg") no-repeat center center/contain;
				}
			}
		}
		.headline-gallery,
		.centered-text {
			text-align: center;
		}
		.headline-gallery {
			margin-bottom: rem(38px);
		}
	}
	.sec-1 {
		@include breakpoint(large) {
			padding: rem(106px) 0 rem(125px);
		}
		.in-sec-1 {
			// padding-bottom: rem(58px);
		}

		.textCont{
			margin-bottom: rem($base-gap * 4);
			
			@include breakpoint(medium){
				margin-bottom: rem($base-gap * 5);
			}

			@include breakpoint(giant){
				margin-bottom: 0;
			}

			&.lastCont{
				margin-bottom: 0;
			}
			.pagesLink{
				a{
					position: relative;
					padding-right:rem(30px);
					color: $primary;
					font-weight: 700;
					&:after{
						content: '\e900';
						font-family: icomoon,sans-serif;
						position: absolute;
						right: 0;
						top: rem(14px);
						transform: translateY(-50%);
					}

					&:hover,
					&:focus,
					&.active{
						padding-right:rem(35px);
						color:$secondary;
					}
				}
			}
		}
	}

	// .teaser-sec {
	// 	padding-bottom: rem(100px);
	// 	margin-bottom: rem(60px);
	// 	@include breakpoint(medium){
	// 		margin-bottom: rem(60px);
	// 	}
	
	// 	@include breakpoint(large) {
	// 		padding: rem(143px) 0 rem(143px);
	// 		margin-bottom: rem(145px);
	// 	}
	// 	&.lazy-loaded {
	// 		background: image-url("layout/tiny-teaser-bg.jpg");
	// 		background-size: cover;
	// 		background-position: center center;
	// 		background-repeat: no-repeat;
	// 		@include breakpoint(tiny) {
	// 			background: image-url("layout/small-teaser-bg.jpg");
	// 		}
	// 		@include breakpoint(small) {
	// 			background: image-url("layout/medium-teaser-bg.jpg");
	// 		}
	// 		@include breakpoint(medium) {
	// 			background: image-url("layout/large-teaser-bg.jpg");
	// 		}
	// 		@include breakpoint(large) {
	// 			background: image-url("layout/teaser-bg.jpg");
	// 		}
	// 	}
	// 	@include breakpoint(giant) {
	// 		body:not(.cms-page-edit) & {
	// 			background-attachment: fixed;
	// 		}
	// 	}
	// 	.txt-box {
	// 		text-align: center;
	// 		// padding-bottom: rem(30px);
	// 		@include breakpoint(medium) {
	// 			// padding: rem(60px) 0;
	// 		}
	// 		@include breakpoint(large) {
	// 			// padding: rem(100px) rem(30px);
	// 		}
	// 		@include breakpoint(huge) {
	// 			// padding: rem(155px) rem(65px);
	// 		}

	// 		&.txt-box01{
	// 			margin-bottom: rem(60px);
	// 			@include breakpoint(large){
	// 				margin-bottom: rem(100px)
	// 			}
	// 			@include breakpoint(huge){
	// 				margin-bottom: rem(130px)

	// 			}
	// 		}			

			
	// 		&.txt-box02,
	// 		&.txt-box03{
	// 			margin-bottom: rem(50px);
	// 			@include breakpoint(medium){
	// 				margin-bottom: 0;
	// 			}
	// 		}

	// 		.h2 {
	// 			margin-bottom: rem(17px);
	// 			p {
	// 				margin-bottom: 0;
	// 			}
	// 		}
	// 	}
	
	// }


	///end teaser

	// .sec-2 {
	// 	padding-bottom: rem(100px);
	// 	@include breakpoint(medium) {
	// 		padding-bottom: rem(145px);
	// 	}
	// 	@include breakpoint(large) {
	// 		padding-bottom: rem(145px);
	// 	}
	// 	&.lazy-loaded {
	// 		.flower-box {
	// 			&:before {
	// 				background: image-url("layout/logo-flowers.jpg") no-repeat center center/contain;
	// 			}
	// 			// &:after {
	// 			// 	background: image-url("layout/rose.png") no-repeat center center/contain;
	// 			// 	@include breakpoint(large) {
	// 			// 		animation: zoom-flower 1s 2s forwards;
	// 			// 	}
	// 			// }
	// 		}
	// 	}
		
	// 	.flower-box {
	// 		position: relative;
	// 		border: 2px solid #a2ccb4;
	// 		text-align: center;
	// 		padding: rem(80px) rem(18px) rem(119px);
	// 		@include breakpoint(large) {
	// 			padding: rem(158px) rem(55px) rem(119px) rem(55px);
	// 			height: 100%;
	// 		}
	// 		&:before {
	// 			position: absolute;
	// 			content: "";
	// 			width: rem(183px);
	// 			height: rem(54px);
	// 			top: rem(20px);
	// 			left: 50%;
	// 			transform: translateX(-50%);
	// 			@include breakpoint(large) {
	// 				top: rem(91px);
	// 			}
	// 		}
	// 		&:after {
	// 			position: absolute;
	// 			content: "";
	// 			width: rem(355px);
	// 			height: rem(329px);
	// 			right: rem(-84px);
	// 			bottom: rem(-175px);
	// 			@include breakpoint(medium) {
	// 				width: rem(555px);
	// 				height: rem(529px);
	// 				right: rem(-184px);
	// 				bottom: rem(-275px);
	// 				opacity: 0;
	// 			}
	// 		}
	// 		.btn {
	// 			z-index: 5;
	// 			@include breakpoint(tiny, max) {
	// 				font-size: rem(17px);
	// 			}
	// 		}
	// 		.h2 {
	// 			p {
	// 				margin-bottom: 0;
	// 			}
	// 		}
	// 		.cms-artcle {
	// 			position: relative;
	// 			z-index: 5;
	// 		}
	// 	}
	// }
}

.datenschutzerklaerung {
	h2,
	h3,
	h4,
	h5,
	h6,
	.h2,
	.h3,
	.h4,
	.h5,
	.h6 {
		margin-bottom: rem(10px) !important;
	}
}

#privacy-policy {
	a {
		display: inline-block;
		word-break: break-all;
	}
}

.WidgetBannerContainer {
	z-index: 999 !important;
	body.cms-template-edit &,
	body.cms-page-edit & {
		display: none;
	}
}


.popupheadline {

	strong {
		font-size: 24px;

		@include breakpoint (small) {
		font-size: 34px;
		}
	}
}

.popuptext {

	strong {
		font-size: 21px;

		@include breakpoint (small) {
		font-size: 34px;
		}
	}
}




// FOOTER
//////////////////////////////

#footer {
	position: relative;
	background: #f7f7f7;
	padding-bottom: rem(67px);
	@media screen and (min-width: 481px) {
		padding-bottom: 0;
	}
	// &.lazy-loaded {
	// 	&:before {
	// 		background: image-url("layout/purple-flower.png") no-repeat center center/contain;
	// 	}
	// }
	// body.sub-template & {
	// 	&:before {
	// 		position: absolute;
	// 		content: "";
	// 		width: rem(145px);
	// 		height: rem(106px);
	// 		top: rem(-100px);
	// 		left: rem(-45px);

	// 		@include breakpoint(large){
	// 			width: rem(165px);
	// 			height: rem(126px);
	// 			top: rem(-114px);
	// 			left: rem(-57px);
	// 		}

	// 		@include breakpoint(cut2) {
	// 			top: rem(-150px);
	// 			left: rem(102px);
	// 		}
	// 	}
	// }
	.cms-map {
		height: rem(350px);
		@include breakpoint(small) {
			height: rem(450px);
		}
		@include breakpoint(large) {
			width: calc(100% - 60px) !important;
			height: 100%;
		}
		> div {
			height: 100%;
			iframe {
				display: block;
				height: 100%;
			}
		}
	}
	.txt-box {
		padding: rem(60px) 0;
		@include breakpoint(large) {
			padding: rem(116px) 0 rem(78px);
		}
	}
	.contacts {
		border-bottom: 2px dotted $medium;
		padding-bottom: rem(46px);
		margin-bottom: rem(48px);
		text-align: center;
		@include breakpoint(tiny, max) {
			font-size: rem(17px);
		}
		@include breakpoint(medium) {
			text-align: left;
		}
		.h2 {
			margin-bottom: rem(28px) !important;
		}
		.footer-data-holder {
			display: flex;
			flex-direction: column;
			@include breakpoint(medium) {
				flex-direction: row;
				justify-content: space-between;
			}
			@include breakpoint(large) {
				flex-direction: column;
			}
			@include breakpoint(giant) {
				flex-direction: row;
			}
			.footer-data {
				span,
				strong {
					display: block;
				}
				strong {
					font-size: rem(21px);
					margin-bottom: rem(10px);
					color: $primary;
				}
				.email {
					display: block;
					text-decoration: none;
					margin-bottom: rem(30px);
					@include breakpoint(medium) {
						margin-bottom: 0;
					}
					@include breakpoint(large) {
						margin-bottom: rem(30px);
					}
					@include breakpoint(giant) {
						margin-bottom: 0;
					}
				}
			}
		}
	}
}

@keyframes zoom-flower {
	0% {
		opacity: 0;
		transform: scale(0);
	}
	100% {
		opacity: 1;
		transform: scale(1);
	}
}

@keyframes appear {
	0% {
		opacity: 0;
		transform: translateX(30px);
	}
	100% {
		opacity: 1;
		transform: translateX(0);
	}
}
@keyframes appear2 {
	0% {
		opacity: 0;
		transform: translate(50%, 70%);
	}
	100% {
		opacity: 1;
		transform: translate(50%, 50%);
	}
}

.popup-row-times{
	background-color:#000000ba;
	display: none;
	position: fixed;
	z-index: 100000;
	margin: auto;
	height: 100%;
	width: 100%;
	justify-content: center;
	align-items: center;
	top: 0;
	
	body.index &{
	display: flex;
	}
	
	.popup{
	width: auto;
	height: auto;
	max-width: 900px;
	background-color: #FFF;
	display: flex;
	flex-direction: column;
	border: solid 2px $primary;
	justify-content: center;
	align-items: center;
	text-align: center;
	padding: 1rem;
	border-radius: $base-gap;
	
		@include breakpoint(small) {
		// padding: 40px 60px;
		}
		
		@include breakpoint(medium) {
		// padding: 80px 120px;
		font-size: 1.2rem;
		line-height: 30px;
		}
		
		.popup-text{
		padding-bottom: .6rem;
		}

		.inhalt {
			padding: 1.5rem;
		}
	}
}

	
.paymentMethod{
	font-weight: 700;
	@include breakpoint(large){
		display: none !important;
	} 
}