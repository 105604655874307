// GLOBAL SETTINGS
//////////////////////////////////////////////

$break: large; // Breakpoint – switch to Desktop version

// NAVIGATION
//////////////////////////////////////////////

$nav-bg: $light; // Navigation Background
$nav-color: $dark-grey; // Navigation Link Color

@mixin nav-button {
	// Default Link Styles
	padding: 1em rem($base-gap);
	text-decoration: none;
	font-weight: 500;
	color: $nav-color;
	border-bottom: 4px solid transparent;
	@extend %animated-transform;
	@include breakpoint($break) {
		font-size: rem(16px);
		padding: rem(20px) rem(10px) rem(16px);
	}
	@include breakpoint(huge) {
		font-size: rem(18px);
		padding: rem(20px) rem(10px) rem(16px);
	}
}
%button-hover {
	// Link Hover
	color: $primary;
	border-bottom: 4px solid $primary;
}
%button-active {
	// Link Active
	color: $primary;
	border-bottom: 4px solid $primary;
}
%prev-icon {
	// Icon to previus Submenu
	font-family: $icon-font;
	content: map-get($icon-map, long-arrow-right);
	transform: rotateY(180deg);
	color: #518c61;

	// @include breakpoint($break) {
	// 	transform:rotate(90deg);
	// }
}
%next-icon {
	// Icon to next Submenu
	font-family: $icon-font;
	content: map-get($icon-map, long-arrow-right);
	color: #518c61;

	@include breakpoint($break) {
		transform: rotate(90deg);
	}
}
$close-icon: map-get($icon-map, times); // Icon to close Submenu

// Quickbar
//////////////////////////////////////////////

$bar-height: 50px; // height (in px)
$bar-pos: top; // position (top, bottom)
$bar-hide: true; // if bar hides on scroll (true, false)

$bar-bg: $primary; // background-color
$bar-color: $light; // children colors – texts and burgerbutton

//////////////////////////////////////////////
// NAVIGATION FUNCTIONALITY
//////////////////////////////////////////////

@import "../modules/ruffnav";

// Navigation Styles
////
@include page-navi {
	background-color: $nav-bg;
	z-index: 1001;
	// padding-bottom: rem(120px);
	.navi-col {
		@include breakpoint($break, max) {
			padding: 0;
		}
		.top-bar,
		.phone-holder {
			@include breakpoint($break, max) {
				display: none;
			}
		}
	}
	nav {
		@include ul {
			&.navi {
				padding-bottom: rem(120px);
				li {
					border-bottom: 1px solid $medium;
					&:last-child {
						border-bottom: none;
						// margin-bottom: rem(18px);
					}
					@include nav-buttons {
						@include nav-button;
					}
					@include has-sub {
						@include sub {
							background: $nav-bg;
							padding-bottom: rem(120px);

							&:before {
								padding: 1em rem($base-gap);
								background-color: rgba($green, 0.2);
							}
						}
					}
					@include nav-back {
						border-bottom: 1px solid $medium;
					}
				}
			}
		}
	}
}

// Navigation Desktop Styles
////
@include breakpoint($break) {
	@include desktop-version-functionality;

	#navigation {
		.scrolling-down & {
			box-shadow: 0px 0px 30px 0px rgba(105,105,105,0.2);
		}
		.top-line {

			@include breakpoint(large){
				max-width: calc(100% - 280px);
				margin: 0 0 0 auto;
			}

			@include breakpoint(huge) {
				max-width: calc(100% - 400px);
				margin: 0 0 0 auto;
			}
			@include breakpoint(full) {
				max-width: rem(1020px);
				margin: 0 0 0 auto;
			}
			.top-bar {
				display: flex;
				// justify-content: space-between;
				background: #f7f7f7;
				border-bottom: 1px dotted $medium;
				// max-width: calc(100% - 110px);
				margin: 0 0 0 auto;
				justify-content: flex-end;
				@include breakpoint(giant) {
					// max-width: calc(100% - 150px);
					// justify-content: space-around;
					justify-content: flex-end;
				}
				@include breakpoint(huge) {
					max-width: 100%;
					margin: 0;
				}
				li {
					font-size: rem(14px);
					font-style: italic;
					color: $grey;
					padding: rem(14px) rem(10px) rem(11px);

					@include breakpoint(giant) {
						font-size: rem(15px);
						padding: rem(14px) rem(12px) rem(11px);
					}
					@include breakpoint(huge) {
						font-size: rem(15px);
						padding: rem(14px) rem(14px) rem(11px);
					}
					@include breakpoint(full) {
						font-size: rem(16px);
						padding: rem(14px) rem(10px) rem(11px);
					}
					strong {
						font-weight: 700;
						color: #343434;
						padding-left: rem(10px);

						@include breakpoint(giant){
							padding-left: 0;
						}
					}
					&.separator {
						position: relative;
						padding: 0;
						&::before {
							position: absolute;
							content: "";
							width: 1px;
							height: 100%;
							border-right: 1.5px dotted $medium;
						}
					}

					&.openTime{
						font-style: normal;
						color: $light;
						position: relative;
						cursor: pointer;
						&:before{
							content: map-get($icon-map, clock2);
							font-family: icomoon;
							background: $primary;
							border-radius:50%;
							padding: rem(8px);
						}
						span{
							text-align: center;
							background: $primary;
							position: absolute;
							
							bottom: rem(-100px);
							width: rem(320px);
							z-index: 10;
							opacity: 0;
							visibility: hidden;
							transition: 0.3s;
							padding: rem(14px) 0;
							left: rem(-250px);
							@include breakpoint(cut2){
								left: 50%;
								transform: translateX(-50%);
							}
						}

						&:hover,
						&:focus,
						&:active{
							span{
								opacity: 1;
								visibility: visible;
							}
						}
					}

					&.payment{
						// margin-right: rem(20px);
						@include breakpoint(giant){
						margin-right: rem(40px);	
						}
					}
				}
			}
			.nav-holder {
				display: flex;
				justify-content: space-between;
				align-items: center;
				width: 100%;
				.cms-navi {
					width: 100%;
				}
				.phone-holder {
					position: relative;
					// width: rem(356px);
					font-size: rem(16px);
					margin-left: rem(39px);
					padding-left: rem(52px);
					width: rem(170px);
					white-space: nowrap;
					&:before {
						position: absolute;
						font-family: $icon-font;
						content: map-get($icon-map, phone);
						width: rem(28px);
						height: rem(28px);
						top: 0;
						left: 0;
						line-height: rem(28px);
						text-align: center;
						border-radius: 50%;
						background: #5c946c;
						color: $light;
					}
					
					.phone {
						position: relative;
						// margin-right: rem(37px);
						&:after {
							position: absolute;
							content: "";
							width: 1px;
							height: rem(22px);
							top: 2px;
							left: rem(-12px);
							// transform: rotate(90deg);
							border-left: 2px dotted $medium;
						}
					}
					span {
						font-style: italic;
					}
				}
			}
		}
		nav {
			ul {
				&.navi {
					width: 100%;
					padding-bottom: 0;
					li {
						border-bottom: none;
						&.has-sub {
							.sub {
								padding-bottom: 0;
								border: 4px solid $primary;
								// background: transparent;
								left: 50%;
								transform: translateX(-50%);
								box-shadow: 0px 0px 30px 0px rgba(1, 100, 46, 0.5);
								&:before {
									position: absolute;
									content: "";
									width: 100%;
									height: 100%;
									top: 0;
									left: 0;
									background: rgba($light, 0.9);
									filter: blur(5px);
									z-index: -1;
								}
								li {
									border-bottom: 1px solid $medium;
									filter: blur(0px);
									&:last-child {
										border-bottom: none;
									}
									a {
										text-align: center;
										padding: rem(10px) rem($base-gap);
										border-bottom: 2px solid transparent;

										&:hover,
										&:focus,
										&:active,
										&.active {
											border-bottom:2px solid $primary;
										}
									}

									&.nav-back {
										padding: rem(10px) rem($base-gap);
									}
								}
							}
						}
						&.datenschutzerklaerung,
						&.impressum {
							display: none;
						}
						
					}
				}
			}
		}
	}
}

// Quickbar Styles
////
#quickbar {
	background-color: $bar-bg;
	color: $bar-color;
	display: flex;
	// justify-content: space-between;
	align-items: center;
	padding: rem($base-gap);
	z-index: 1000;

	li{

		&.phone{
			margin-right: rem(20px);
		}

		&.openTime{
			// flex-grow: 5;
			font-style: normal;
						color: $light;
						position: relative;
						cursor: pointer;
						&:before{
							content: map-get($icon-map, clock2);
							font-family: icomoon;
							
						}
						span{
							text-align: center;
							background: $primary;
							position: absolute;
							
							bottom: rem(-110px);
							width: rem(320px);
							z-index: 10;
							opacity: 0;
							visibility: hidden;
							transition: 0.3s;
							padding: rem(14px) 0;
							left: 21%;
								transform: translateX(-50%);
						}

						&:hover,
						&:focus,
						&:active{
							span{
								opacity: 1;
								visibility: visible;
							}
						}
		}

		&.menu{
			position: absolute;
			right: rem($base-gap);
		}
	}

	@include breakpoint($break) {
		display: none;
	}
}






//////////////////////////////////////////////
// naviAdd
//////////////////////////////////////////////
.navi-add {
	display: flex;
	flex-direction: column;
	justify-content: center;
	@include breakpoint(small) {
		flex-direction: row;
		justify-content: space-between;
	}
	@include breakpoint(large) {
		max-width: rem(720px);
	}

	li {
		border-bottom: 1px solid $medium;
		@include breakpoint(small) {
			border-bottom: none;
		}
		&:last-child {
			border-bottom: none;
		}
		a {
			display: block;
			text-decoration: none;
			text-align: center;
			font-weight: 500;
			padding: rem(10px) rem(18px);
			color: $dark-grey;
			@include breakpoint(small) {
				display: inline-block;
				padding: 0;
				text-align: left;
			}
			&:hover,
			&:focus,
			&:active,
			&.active {
				color: $primary;
			}
		}
	}
}
